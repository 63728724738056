
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { passwordRulesEl } from "@/utils/verify";
const user = namespace("user");
@Component({})
export default class ChangePwd extends Vue {
  @user.Action changePassword;
  form: any = {
    oldPassword: "",
    password: "",
    confirmPassword: ""
  };
  showPwdTips: boolean = false;
  passPass: boolean = false;
  isServerError: boolean = false;
  changeLoading: boolean = false;
  serverErrorMsg: string = "";
  validatePassword(rule, value, callback) {
    passwordRulesEl(value, callback);
  }
  validateConfirm(rule, value, callback) {
    if (value !== this.form.password) {
      callback(new Error(this.$t("login.password-no-same") as string));
    } else {
      callback();
    }
  }
  handleValid(prop, isPass, msg) {
    if (prop === "password") {
      if (isPass) {
        this.passPass = true;
      } else {
        this.passPass = false;
      }
    }
  }
  get rules() {
    return {
      oldPassword: [
        {
          required: true,
          message: this.$t("login.password-empty-tips"),
          trigger: "blur"
        }
      ],
      password: [{ validator: this.validatePassword, trigger: "change" }],
      confirmPassword: [
        {
          required: true,
          message: this.$t("login.password-empty-tips"),
          trigger: "blur"
        },
        { validator: this.validateConfirm, trigger: "blur" }
      ]
    };
  }
  submitForm(refName) {
    (this.$refs[refName] as any).validate(valid => {
      if (valid) {
        this.changeLoading = true;
        this.changePassword(this.form).then(
          data => {
            this.$router.push("/change-success?from=change-pwd");
          },
          data => {
            this.serverErrorMsg = data.message;
            this.isServerError = true;
            this.changeLoading = false;
          }
        );
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  created() {
    // this.getNavData();
  }
}
